import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import route from '../../../src/common/utils/route'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  tablet?: boolean
  desktop?: boolean
  data?: InSeoData | ImageInputData
}

const LandingAllSeasonEs = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Neumáticos 4 estaciones" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Existe un tipo de neumático que puede adaptarse a nuestras necesidades
          tanto en temporada de invierno como en temporada de verano. Se les
          conoce comúnmente como{' '}
          <strong>
            Neumáticos 4 estaciones, neumáticos Todo el Año o neumáticos All
            Season.{' '}
          </strong>
          Esta clase de neumáticos son una opción óptima con garantía de{' '}
          <strong>alto rendimiento </strong>en una gran variedad de superficies
          y condiciones climatológicas. Cuentan con la más avanzada tecnología
          en diseño de neumáticos y permiten adaptarse a situaciones de{' '}
          <strong>lluvia, granizo, aguanieve y nieve.</strong>
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>La evolución de los neumáticos para todo el año</h2>
          <p>
            Los <strong>Neumáticos All Season</strong> han evolucionado mucho a
            lo largo de los últimos años. En la actualidad son un{' '}
            <strong>
              tipo de neumático certificado para su uso en invierno,
            </strong>{' '}
            con lo que podrás utilizarlos{' '}
            <strong>sin necesidad de montar cadenas.</strong> Este tipo de
            neumático supone una alternativa real a las cadenas y un equilibrio
            de prestaciones en condiciones de verano y en invierno.
          </p>
          <p>
            Los <strong>Neumáticos 4 estaciones </strong>son uno de los tipos de
            neumático con mayor crecimiento en el mercado español debido a que
            fue concebido especialmente para ser usado en regiones con inviernos
            suaves como es el caso de España.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>¿Por qué elegir Neumáticos 4 estaciones?</h2>
          <p>
            Hay muchos motivos para elegir neumáticos todo el año. Los
            principales son:
          </p>
          <ul>
            <li>
              Están disponibles para diferentes tipos de vehículos: coches, 4x4
              y furgonetas.
            </li>
            <li>
              Evitan la necesidad de cambiar de ruedas según la temporada del
              año.
            </li>
            <li>
              No requieren del uso de cadenas en situaciones de hielo o nieve.
            </li>
            <li>
              Ofrecen un excelente rendimiento bajo cualquier circunstancia.
            </li>
            <li>
              Son garantía de seguridad ante todo tipo de condiciones
              climatológicas, tanto en frio como en calor.
            </li>
            <li>
              Disponen de la última tecnología en lo que a fabricación de
              neumáticos refiere, siendo una de las opciones más completas del
              mercado.
            </li>
          </ul>
          <p>
            Existen diferentes modelos de neumáticos todo el año según cada
            marca de fabricantes, los más famosos y reconocidos del mercado son:
            <strong>
              Michelín Crossclimate, Pirelli Cintauro, Goodgear Vector 4Season o
              Continental AllSeasonContact.
            </strong>
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingAllSeasonCa = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneumàtics 4 estacions" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Hi ha un tipus de pneumàtic que pot adaptar-se a les nostres
          necessitats tant en temporada d'hivern com a temporada d'estiu. Se'ls
          coneix comunament com{' '}
          <strong>
            Pneumàtics 4 estacions, pneumàtics tot l’any o pneumàtics All
            Season.
          </strong>{' '}
          Aquesta classe de pneumàtics són una opció òptima amb garantia d'alt
          rendiment a una gran varietat de superfícies i condicions
          climatològiques. Compten amb la més avançada tecnologia en disseny de
          pneumàtics i permeten adaptar-se a situacions de pluja, calamarsa,
          aiguaneu i neu.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>L’evolució dels pneumàtics per tot l’any</h2>
          <p>
            Els <strong>Pneumàtics All Season</strong> han evolucionat molt al
            llarg dels últims anys. En l'actualitat són un tipus de{' '}
            <strong>pneumàtics certificat pel seu ús a l'hivern</strong>, el
            podràs utilitzar-los{' '}
            <strong>sense necessitat de muntar cadenes</strong>. Aquest tipus de
            pneumàtic suposa una alternativa real a les cadenes i un equilibri
            de prestacions en condicions d'estiu i d’hivern.
          </p>
          <p>
            Els <strong>Pneumàtics 4 estacions</strong> són la classe de
            pneumàtic amb major creixement a Catalunya ja que van ser concebut
            especialment per a ser usat en regions amb hiverns suaus com el cas
            català.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Per què escollir Pneumàtics 4 estacions?</h2>
          <p>
            Hi ha molts motius per triar pneumàtics tot l'any. Els principals
            són:
          </p>
          <ul>
            <li>
              Estan disponibles per a diferents tipus de vehicles: cotxes, 4x4 i
              furgonetes.
            </li>
            <li>
              Eviten la necessitat de canviar de rodes segons la temporada de
              l'any.
            </li>
            <li>
              No requereixen de l'ús de cadenes en situacions de gel o neu.
            </li>
            <li>
              Ofereixen un excel·lent rendiment sota qualsevol circumstància.
            </li>
            <li>
              Són garantia de seguretat davant tot tipus de condicions
              climatològiques, tant en fred com en calor.
            </li>
            <li>
              Disposen de l'última tecnologia pel que fa a fabricació de
              pneumàtics, sent una de les opcions més completes del mercat.
            </li>
          </ul>
          <p>
            Hi ha diferents models de pneumàtics tot l'any segons cada marca de
            fabricants, els més famosos i reconeguts del mercat són:{' '}
            <strong>
              Michelin Crossclimate, Pirelli Cintauro, Goodgear Vector 4Season o
              Continental AllSeasonContact.
            </strong>
          </p>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-allseason'),
        temporada: { selectable: false, value: 'allseason' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'cuatro_estaciones',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer
          showMarcas={false}
          origin={route('landing-allseason')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingAllSeason = ({ ...props }: Props) =>
  locale === 'es' ? (
    <LandingAllSeasonEs {...props} />
  ) : (
    <LandingAllSeasonCa {...props} />
  )

export default LandingAllSeason
